import React, { useState } from 'react';
import { Col, Row } from 'components/Core/Grid';
import Text from 'components/Core/Text';
import Waiting from 'components/Page/Toolbox/Waiting';
import { AlertSettingsSectionLayout } from './styles';
import SettingsMenu from '../SettingsMenu';

const AlertSettingsContent = ({ isWaiting, userInfo = {}, onSave, title = 'Alert Settings' }) => {
  const [values, setValues] = useState(userInfo);

  const setValue = (id, value) => {
    const tmpValues = { ...values };
    tmpValues[id] = value;
    setValues(tmpValues);
  };

  return (
    <AlertSettingsSectionLayout>
      <Row>
        <Col md={6}>
          <SettingsMenu selected={title} userInfo={userInfo} />
        </Col>
        <Col md={18}>
          {isWaiting && <Waiting />}
          {!isWaiting && (
            <Text type="h5" color="white">
              {title}
            </Text>
          )}
        </Col>
      </Row>
    </AlertSettingsSectionLayout>
  );
};

export default AlertSettingsContent;
